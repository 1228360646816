/* eslint-disable react-hooks/exhaustive-deps */
import constate from 'constate';
// import Contract from 'contracts/ABI.json';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Web3 from 'web3';
import { useWeb3React } from '@web3-react/core';
import { SafeAppConnector, useSafeAppConnection } from '@gnosis.pm/safe-apps-web3-react';
import { selectedWalletPersistence } from 'persistence';
import WALLETS from 'config/wallets';
import { InjectedConnector, NoEthereumProviderError } from '@yodaplus/injected-connector';
import tokenTvTJson from '@yodaplus/dapps-lib/contracts/TokenTvT.json';
import PoolContractJson from '@yodaplus/dapps-lib/contracts/PoolContract.json';
import WXDCApothem from '@yodaplus/dapps-lib/contracts/WXDCOZMainnet.json';
import ERC20 from '@yodaplus/dapps-lib/contracts/ERC20.json';
import { initWeb3Onboard } from 'config/onboard';
import { useConnectWallet } from '@web3-onboard/react';
import {
  sendTransaction,
  sendTransactionHashOnly,
  WEB3_STATUS,
  currentNetwork,
  transformProviderFromXinfin,
  readOnlyWeb3,
  sendNativeTransaction,
  getTransactionHash,
  xdcToEthAddress,
  toChecksumAddress
} from 'helpers/web3';
import { useSnackbar } from 'notistack';
import { NETWORK_ID } from 'config';

require('dotenv').config();

const safeAppConnector = new SafeAppConnector();

const POLL_INTERVAL = 1000;

const getOnboard = ({ onProvider }) => {
  return initWeb3Onboard;
};

const useMultisigStatus = () => {
  const [isMultisig, setIsMultisig] = useState(null);

  useEffect(() => {
    safeAppConnector.isSafeApp().then(setIsMultisig);
  }, []);

  return { isMultisig };
};

const Web3State = () => {
  const {
    activate,
    deactivate,
    active: web3Active,
    account: web3Account,
    library: web3ReactWeb3,
    chainId
  } = useWeb3React();

  const [status, setStatus] = useState(WEB3_STATUS.UNKNOWN);
  const [balance, setBalance] = useState('0');
  const [account, setAccount] = useState('');
  const [walletWeb3, setwalletWeb3] = useState(web3ReactWeb3);
  const [active, setActive] = useState(web3Active);
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const { isMultisig } = useMultisigStatus();
  console.log({ NETWORK_ID });
  // const { throwErrorMessage } = useAppState();

  const web3 = walletWeb3 ?? readOnlyWeb3;

  if (!web3) {
    throw new Error('web3 must be available at this point');
  }
  useEffect(() => {
    if (wallet?.provider) {
      setAccount(toChecksumAddress(wallet.accounts[0].address));
    }
  }, [wallet]);
  useEffect(() => {
    if (web3Active && web3Account) {
      setActive(true);
      setAccount(toChecksumAddress(web3Account));
      setwalletWeb3(web3ReactWeb3);
    }
  }, [web3Active, web3Account]);
  // create a function to show the snackbar when a transaction is sent and closed when it is mined

  const onProvider = useCallback(
    async (provider) => {
      setStatus(WEB3_STATUS.UNKNOWN);

      const connector =
        typeof provider.safe !== 'undefined'
          ? safeAppConnector
          : new InjectedConnector({ provider });

      try {
        await activate(connector, undefined, true);
        setStatus(WEB3_STATUS.READY);
      } catch (e) {
        if (e instanceof NoEthereumProviderError) {
          setStatus(WEB3_STATUS.UNAVAILABLE);
        }
      }
    },
    [activate]
  );

  const onboard = useMemo(() => getOnboard({ onProvider }), [onProvider]);

  const connectWallet = useCallback(
    async (wallet) => {
      console.log('🚀 ~ file: useWeb3.ts:69 ~ wallet:', wallet);
      const selected = wallet
        ? await onboard.connectWallet({
            autoSelect: { label: wallet, disableModals: true }
          })
        : await onboard.connectWallet();
      if (!selected) {
        return false;
      }
      const wallets = onboard.state.get();
      const connectedWallet = wallets?.wallets[0];
      console.log('🚀 ~ file: useWeb3.ts:88 ~ connectedWallet:', connectedWallet);
      if (connectedWallet) {
        selectedWalletPersistence.set(connectedWallet.label);
        const connector = new InjectedConnector({
          provider: transformProviderFromXinfin(connectedWallet.provider)
        });
        await connect();
        try {
          if (connectedWallet.label === 'WalletConnect') {
            setAccount(toChecksumAddress(connectedWallet.accounts[0].address));
            console.log('connectedWallet.accounts[0].address', connectedWallet.accounts[0].address);
            // change the rpc url in the provider by https://rpc.xinfin.network in initial stage
            const _provider = connectedWallet.provider;
            console.log('connectedWallet.provider', _provider);
            // _provider.connector.signer.rpcProviders.eip155.httpProviders['1'].url =
            //   'https://rpc.xinfin.network';
            // _provider.connector.signer.rpcProviders.eip155.httpProviders['1'].connection.url =
            //   'https://rpc.xinfin.network';
            const _web3 = new Web3(_provider);

            console.log(
              'getBalance',
              await _web3.eth.getBalance(connectedWallet.accounts[0].address)
            );
            setBalance(await readOnlyWeb3.eth.getBalance(connectedWallet.accounts[0].address));
            console.log('connectedWallet.provider)', _provider);
            console.log('provider', _web3);

            setwalletWeb3(_web3);
            setActive(true);
            setStatus(WEB3_STATUS.READY);
            return true;
          }

          console.log('activating');
          await activate(connector, undefined, true);
          setStatus(WEB3_STATUS.READY);
        } catch (e) {
          console.error(e);
          if (e instanceof NoEthereumProviderError) {
            setStatus(WEB3_STATUS.UNAVAILABLE);
          }
        }
      }

      return true;
    },
    [onboard]
  );

  const disconnectWallet = async () => {
    const [primaryWallet] = onboard.state.get().wallets;
    await onboard.disconnectWallet({ label: primaryWallet.label });
    deactivate();
    setAccount('');
    setActive(false);
    selectedWalletPersistence.clear();
    if (wallet?.provider) {
      disconnect({ label: wallet?.label });
    }
  };
  // Setting up Pool Controller Contract
  const poolController = useMemo(
    () =>
      new web3.eth.Contract(
        currentNetwork.controllerContractAbi,
        currentNetwork.controllerContractAddress,
        {
          from: account
        }
      ),
    [web3, account]
  );
  const escrowManager = useMemo(
    () =>
      new web3.eth.Contract(currentNetwork.escrowManagerABI, currentNetwork.escrowManagerAddress, {
        from: account
      }),
    [web3, account]
  );
  // Setting up the Controller Contract
  const controllerContract = useMemo(
    () =>
      new web3.eth.Contract(
        currentNetwork.custodianContractABI,
        currentNetwork.custodianContractAddress,
        {
          from: account
        }
      ),
    [web3, account]
  );

  // Setting up the WXDC Contract
  const wxdcoz = useMemo(
    () =>
      new web3.eth.Contract(WXDCApothem.abi, '0x1daFF763225dD39d8740E131000A97043b5234F9', {
        from: account,
        gasPrice: 1 * 10 ** 9
      }),
    [web3, walletWeb3, account]
  );

  // Setting up the Pool Contract
  const poolContract = useMemo(() => {
    return new web3.eth.Contract(PoolContractJson.abi, {
      from: account
    });
  }, [web3, account]);

  // Setting Up Token TVT contract
  const tokenTvTContract = useMemo(() => {
    return new web3.eth.Contract(tokenTvTJson.abi, {
      from: account
    });
  }, [web3, account]);

  const erc20Contract = useMemo(
    () =>
      new web3.eth.Contract(WXDCApothem.abi, {
        from: account
      }),
    [web3, account]
  );

  const wrapContractCall = useCallback(
    (func) => {
      return (...args) => {
        if (!controllerContract) {
          throw new Error('Smart contract is not available');
        }

        return func(...args);
      };
    },
    [controllerContract]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const publishPool = useCallback(
    wrapContractCall(
      (
        coinBaseAddress,
        feesCutPercentage,
        nftHoldersHairCut,
        nonNftHoldersHairCut,
        feesAddress,
        nodeOwner,
        devPoolFund,
        primeNumberFund,
        xdcsFund
      ) => {
        console.log({
          coinBaseAddress,
          feesCutPercentage,
          nftHoldersHairCut,
          nonNftHoldersHairCut,
          feesAddress,
          nodeOwner,
          devPoolFund,
          primeNumberFund,
          xdcsFund
        });
        return poolController.methods
          .publishPool(
            coinBaseAddress,
            feesCutPercentage,
            nftHoldersHairCut,
            nonNftHoldersHairCut,
            feesAddress,
            nodeOwner,
            devPoolFund,
            primeNumberFund,
            xdcsFund
          )
          .send({ from: currentNetwork.account });
      }
    ),
    [wrapContractCall, web3, poolController]
  );
  // -------Controller Contract--------------------------/
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const publishToken = useCallback(
    wrapContractCall(
      (
        tokenName,
        tokenSymbol,
        tokenValue,
        maxSupply,
        kycproviderPrimaryAddress,
        minSubscription,
        paymentTokens,
        subscriptionPeriod,
        maturityPeriod,
        allowedCountries,
        classification,
        onChainKyc,
        liquidityPool
      ) => {
        console.log('tokenName: ', tokenName);
        console.log('tokenSymbol: ', tokenSymbol);
        // console.log('tokenDecimals: ', tokenDecimals);
        console.log('tokenValue: ', tokenValue);
        console.log('minSubscription: ', minSubscription);
        console.log('kycproviderPrimaryAddress: ', kycproviderPrimaryAddress);
        console.log('paymentTokens', paymentTokens);
        console.log('maturityPeriod', maturityPeriod);
        console.log('liquidityPool', liquidityPool);

        return sendTransaction(
          web3,
          controllerContract.methods.publishToken({
            name: tokenName,
            symbol: tokenSymbol,
            maxTotalSupply: maxSupply,
            subscriptionPeriod,
            value: tokenValue,
            kycProviderAddress: kycproviderPrimaryAddress,
            minSubscription,
            paymentTokens,
            maturityPeriod,
            countries: allowedCountries,
            investorClassifications: classification,
            onChainKyc,
            liquidityPool
          })
        );
      }
    ),
    [wrapContractCall, web3, controllerContract]
  );

  const addWhiteList = useCallback(
    wrapContractCall((addresslist) => {
      console.log('addresslist: ', addresslist);
      return sendTransaction(web3, controllerContract.methods.addWhitelist(addresslist));
    }),
    [wrapContractCall, web3, controllerContract]
  );
  const getOwner = useCallback(
    wrapContractCall(() => {
      return controllerContract.methods.owner().call();
    }),
    [wrapContractCall, web3, controllerContract]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const removeWhiteList = useCallback(
    wrapContractCall((addresslist) => {
      return sendTransaction(web3, controllerContract.methods.removeWhitelist(addresslist));
    }),
    [wrapContractCall, web3, controllerContract]
  );

  // --------------WXDC CONTRACT-----------------------//

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchWXDCBalance = useCallback(
    wrapContractCall((poolAddress) => {
      return wxdcoz.methods.balanceOf(poolAddress).call();
    }),
    [wrapContractCall, web3, poolContract]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const wxdcBalanceOf = useCallback(
    wrapContractCall(async () => {
      console.log('account', account);
      return wxdcoz.methods.balanceOf(account).call();
    }, [wrapContractCall, web3, wxdcoz])
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const depositXDC = useCallback(
    wrapContractCall((amount) => {
      return sendTransaction(web3, wxdcoz.methods.deposit(), {
        value: web3.utils.toWei(amount.toString(), 'ether')
      });
    }),
    [wrapContractCall, web3, controllerContract]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const withDrawXDC = useCallback(
    wrapContractCall((amount) => {
      return sendTransaction(
        web3,
        wxdcoz.methods.withdraw(web3.utils.toWei(amount.toString(), 'ether'))
      );
    }),
    [wrapContractCall, web3, controllerContract]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const approveWxdc = useCallback(
    wrapContractCall((spender, amount) => {
      return sendTransaction(
        web3,
        wxdcoz.methods.approve(spender, web3.utils.toWei(amount.toString(), 'ether'))
      );
    }),
    [wrapContractCall, web3, wxdcoz]
  );

  // -------------Token TVT Contract-------------------//

  // eslint-disable-next-line
  const tvtIssue = useCallback(
    wrapContractCall((tokenAddress, subscriber, qty) => {
      tokenTvTContract.options.address = tokenAddress;
      return sendTransaction(web3, tokenTvTContract.methods.issue(subscriber, qty));
    }),
    [wrapContractCall, web3, tokenTvTContract]
  );
  const checkInvestorRefresh = useCallback(
    wrapContractCall((tokenAddress) => {
      tokenTvTContract.options.address = tokenAddress;
      return tokenTvTContract.methods.checkRefreshedRecently().call();
    }),
    [web3, tokenTvTContract, wrapContractCall]
  );

  const claimRewards = useCallback(
    wrapContractCall(
      (poolAddress) => {
        poolContract.options.address = poolAddress;
        return sendTransaction(web3, poolContract.methods.claimRewards());
      },
      [wrapContractCall, web3, poolContract]
    )
  );

  const getIssuanceEscrowCondition = useCallback(
    wrapContractCall((orderId) => {
      return escrowManager.methods.checkIssuanceEscrowConditions(orderId).call();
    }),
    [wrapContractCall, web3, escrowManager]
  );

  const fetchUnClaimedRewards = useCallback(
    wrapContractCall(
      (poolAddress, accountAddress) => {
        poolContract.options.address = poolAddress;
        return poolContract.methods.balanceOfUnClaimedRewards(accountAddress).call();
      },
      [wrapContractCall, web3, poolContract]
    )
  );

  const getRewardsLogs = useCallback(
    wrapContractCall((poolAddress) => {
      poolContract.options.address = poolAddress;
      return poolContract.methods.getRewardsLogs(account).call();
    }),
    [wrapContractCall, web3, poolContract]
  );

  const getRedeemptionEscrowCondition = useCallback(
    wrapContractCall((orderId) => {
      return escrowManager.methods.checkRedemptionEscrowConditions(orderId).call();
    }),
    [wrapContractCall, web3, escrowManager]
  );
  const poolApprove = useCallback(
    wrapContractCall((poolAddress, spender, amount) => {
      poolContract.options.address = poolAddress;
      return sendTransaction(
        web3,
        poolContract.methods.approve(
          spender,
          WXDCApothem.address,
          web3.utils.toWei(amount.toString(), 'ether')
        )
      );
    }),
    [wrapContractCall, web3, poolContract]
  );

  const matureBalance = useCallback(
    wrapContractCall((tokenAddress) => {
      tokenTvTContract.options.address = tokenAddress;
      return tokenTvTContract.methods.matureBalanceOf(account).call();
    }),
    [web3, tokenTvTContract, wrapContractCall]
  );
  const changeMaturityDate = useCallback(
    wrapContractCall((tokenAddress, maturityDate) => {
      tokenTvTContract.options.address = tokenAddress;
      return sendTransaction(web3, tokenTvTContract.methods.setmaturityEndTimeStamp(maturityDate));
    }),
    [wrapContractCall, web3, tokenTvTContract]
  );
  const setMaxSupplyToken = useCallback(
    wrapContractCall((tokenAddress, maxSupply) => {
      tokenTvTContract.options.address = tokenAddress;
      return sendTransaction(web3, tokenTvTContract.methods.setMaxSupply(maxSupply));
    }),
    [wrapContractCall, web3, tokenTvTContract]
  );
  const redeemTvT = useCallback(
    wrapContractCall((tokenAddress, amount) => {
      tokenTvTContract.options.address = tokenAddress;
      return sendTransaction(web3, tokenTvTContract.methods.redeem(account, amount));
    }),
    [wrapContractCall, web3, tokenTvTContract]
  );
  const approveTradeToken = useCallback(
    wrapContractCall((tokenAddress, spender, amount) => {
      tokenTvTContract.options.address = tokenAddress;

      return sendTransaction(web3, tokenTvTContract.methods.increaseAllowance(spender, amount));
    }),
    [wrapContractCall, web3, erc20Contract]
  );

  const refreshInvestors = useCallback(
    wrapContractCall((tokenAddress) => {
      tokenTvTContract.options.address = tokenAddress;
      return sendTransaction(web3, tokenTvTContract.methods.refreshInvestorList());
    }),
    [wrapContractCall, web3, erc20Contract]
  );
  const wxdcAllowance = useCallback(
    wrapContractCall(() => {
      return wxdcoz.methods.allowance(account, currentNetwork.escrowManagerAddress).call();
    }, [wrapContractCall, web3, wxdcoz])
  );
  const swapRedeemptionOrder = useCallback(
    wrapContractCall((orderId) => {
      return sendTransaction(web3, escrowManager.methods.swapRedemption(orderId));
    }),
    [web3, escrowManager, wrapContractCall]
  );

  const swapIssuanceOrder = useCallback(
    wrapContractCall((orderId) => {
      return sendTransaction(web3, escrowManager.methods.swapIssuance(orderId));
    }),
    [web3, escrowManager, wrapContractCall]
  );

  const setNodeTokenAddress = useCallback(
    wrapContractCall((poolAddress, tokenAddress) => {
      poolContract.options.address = poolAddress;
      tokenAddress = xdcToEthAddress(tokenAddress);
      return sendTransactionHashOnly(web3, poolContract.methods.setNodeTokenAddress(tokenAddress));
    }),
    [wrapContractCall, web3, poolContract]
  );
  const wrapXDC = useCallback(
    wrapContractCall((poolAddress) => {
      poolContract.options.address = poolAddress;
      return sendTransaction(web3, poolContract.methods.wrap());
    }),
    [wrapContractCall, web3, poolContract]
  );
  const fetchContractBalance = useCallback(
    wrapContractCall((poolAddress) => {
      poolContract.options.address = poolAddress;
      return poolContract.methods.getNativeBalance().call();
    }),
    [wrapContractCall, web3, poolContract]
  );

  const sendEth = useCallback(
    wrapContractCall((addressToSend, amount) => {
      poolContract.options.address = addressToSend;
      return sendTransaction(web3, poolContract.methods.distributeRewardsManual(), {
        value: web3.utils.toWei(amount.toString(), 'ether')
      });
    }),
    [wrapContractCall, web3]
  );

  useEffect(() => {
    if (!web3 || !account) {
      setBalance('0');
      return;
    }

    let timerId = null;
    let canceled = false;

    const poll = async () => {
      timerId = null;

      try {
        const balance_ = await readOnlyWeb3.eth.getBalance(account);

        if (!canceled) {
          setBalance(balance_);
        }
      } catch (e) {
        console.warn(`Something is wrong when polling for account balance: ${e}`);
      }

      if (!canceled) {
        timerId = setTimeout(poll, POLL_INTERVAL);
      }
    };

    poll();

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }

      canceled = true;
    };
  }, [web3, account]);

  useEffect(() => {
    if (isMultisig === null || isMultisig === true) {
      return;
    }

    (async () => {
      const selectedWallet = selectedWalletPersistence.get();

      if (!selectedWallet) {
        return;
      }

      const connected = await connectWallet(selectedWallet);

      if (!connected) {
        selectedWalletPersistence.clear();
      }
    })();
    // eslint-disable-next-line
  }, [isMultisig]);

  const triedToConnectToSafe = useSafeAppConnection(safeAppConnector);

  useEffect(() => {
    if (triedToConnectToSafe) {
      console.log('Connected triedToConnectToSafe', triedToConnectToSafe);
    }
  }, [triedToConnectToSafe]);

  return {
    isMultisig,
    status,
    active,
    account,
    chainId,
    web3,
    balance,
    connectWallet,
    disconnectWallet,
    // Controller Contract
    publishToken,
    addWhiteList,
    removeWhiteList,
    getOwner,
    // Pool Controller Contract
    publishPool,
    setNodeTokenAddress,
    wrapXDC,
    fetchContractBalance,
    // wxdc Contract
    approveWxdc,
    fetchWXDCBalance,
    depositXDC,
    withDrawXDC,
    wxdcBalanceOf,
    // Token TVT contract
    tvtIssue,
    approveTradeToken,
    matureBalance,
    redeemTvT,
    claimRewards,
    fetchUnClaimedRewards,
    getIssuanceEscrowCondition,
    getRedeemptionEscrowCondition,
    swapIssuanceOrder,
    swapRedeemptionOrder,
    getRewardsLogs,
    poolApprove,
    wxdcAllowance,
    changeMaturityDate,
    setMaxSupplyToken,
    refreshInvestors,
    checkInvestorRefresh,
    sendEth
  };
};

export const [Web3StateProvider, useWeb3State] = constate(Web3State);
